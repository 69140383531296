import "./App.css";
import { useState, useEffect } from "react";
import Cell from "./components/Cell";
import Button from "./components/Button";

function App() {
  const LOCALSTORAGE_KEY = "mediMemoList";

  const defaultMemos = [
    "アレジオン  エピナスチン 60日",
    "アンテベート軟膏の混合（カラダの薬）",
    "プロペト",
    "リンデロン - VGローション",
    "バルトレックス",
    "ジフラール→デルモデート（指）",
    "コレクチム軟膏（旧タクロリムス・プロトピック）",
    "リドメックス（スピラゾン）軟膏（首など）",
  ];

  const [activeMemos, setActiveMemos] = useState([]);
  const [inactiveMemos, setInactiveMemos] = useState(defaultMemos);

  useEffect(() => {
    const items = localStorage.getItem(LOCALSTORAGE_KEY);
    if (items) {
      const obj = JSON.parse(items);
      setActiveMemos(obj["active"]);
      setInactiveMemos(obj["inactive"]);
    }
  }, []);

  function saveMemos(activeMemos, inactiveMemos) {
    const memos = { active: activeMemos, inactive: inactiveMemos };
    const json = JSON.stringify(memos);
    localStorage.setItem(LOCALSTORAGE_KEY, json);
  }

  return (
    <div style={styles.container} className="App">
      <nav style={styles.navbar} className="navbar navbar-dark bg-info">
        <span className="navbar-brand mb-0 h1">おくすりリスト</span>
        <Button
          title="Clear"
          onClick={() => {
            localStorage.removeItem(LOCALSTORAGE_KEY);
            setActiveMemos([]);
            setInactiveMemos(defaultMemos);
          }}
        />
      </nav>
      {activeMemos.map((memo, index) => (
        <Cell
          key={index}
          title={memo}
          checked
          onClick={() => {
            const item = activeMemos[index];
            let w_inactiveMemos = inactiveMemos.slice();
            w_inactiveMemos.unshift(item);
            setInactiveMemos(w_inactiveMemos);
            let w_activeMemos = activeMemos.slice();
            w_activeMemos.splice(index, 1);
            setActiveMemos(w_activeMemos);
            saveMemos(w_activeMemos, w_inactiveMemos);
          }}
        />
      ))}
      {/* <div style={styles.divider}></div> */}
      {inactiveMemos.map((memo, index) => (
        <Cell
          key={index}
          title={memo}
          onClick={() => {
            const item = inactiveMemos[index];
            let w_activeMemos = activeMemos.slice();
            w_activeMemos.push(item);
            setActiveMemos(w_activeMemos);
            let w_inactiveMemos = inactiveMemos.slice();
            w_inactiveMemos.splice(index, 1);
            setInactiveMemos(w_inactiveMemos);
            saveMemos(w_activeMemos, w_inactiveMemos);
          }}
        />
      ))}
    </div>
  );
}

const styles = {
  container: {
    // backgroundColor: 'lightgray',
  },
  navbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 24,
    // fontWeight: 'bold',
    color: "white",
    backgroundColor: "#397CBB",
    height: 60,
  },
  divider: {
    height: 44,
    backgroundColor: "#F5F5F5",
    borderBottom: "solid 1px #DDDDDD",
  },
};

export default App;
