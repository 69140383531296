import React from 'react';

export default function Button(props) {
    const { title, onClick } = props;
    return (
        <div style={styles.container} onClick={onClick}>
            <span style={styles.title}>{title}</span>
        </div>
    )
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 4,
        paddingBottom: 4,   
        paddingLeft: 10, 
        paddingRight: 10, 
        color: '#DDD',
        // backgroundColor: 'transparent',
    },
    title: {
        fontWeight: 'bold',
        fontSize: 16,
    },
}

