import React from 'react';

export default function Cell(props) {
    const { title, checked, onClick } = props;
    let containerStyle = styles.container;
    if (!checked) {
        containerStyle = {...containerStyle, ...styles.grayed};
    }
    return (
        <div style={containerStyle}>
            <div style={styles.title}>{title}</div>
            <div className="btn" style={styles.checkbox} onClick={onClick}>
                {checked ? <i className="far fa-check-square"></i> 
                : <i className="far fa-square"></i>}
            </div>
        </div>
        
    )
}

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 20,
        paddingBottom: 20,   
        paddingLeft: 20, 
        paddingRight: 10, 
        borderBottom: 'solid 1px #DDDDDD',
        backgroundColor: 'white',
    },
    title: {
        fontWeight: 'bold',
    },
    grayed: {
        color: '#666',
        // backgroundColor: '#F0F0F0',
        // color: 'white',
        // backgroundColor: '#777',
        backgroundColor: 'transparent',
    },
    checkbox: {
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 24,
        // width: 60,
        // textAlign: 'right',
    }
}

